@use '@angular/material' as mat;
@use 'src/assets/css/themes/dark';
@use 'src/assets/css/themes/light';
@import 'src/assets/css/config';

//$mat-theme-ignore-duplication-warnings: true;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
@include mat.core();

.mat-raised-button,
.mat-flat-button,
.mat-button {
  i {
    margin-top: -3px;
    margin-left: -10px;
  }
}

.mat-button-wrapper {
  .fa,
  .fab,
  .far,
  .fas {
    padding: 7px;
  }
}

.mat-button-fa {
  vertical-align: inherit !important;
}

.mat-menu-item {
  .fab,
  .far,
  .fas {
    padding-right: 10px;
  }
}

.mat-mdc-card {
  border: 1px solid transparent;
  border-radius: 0 !important;

  .mat-mdc-card-title {
    font-size: 1em;
  }
}

.mat-toolbar,
.mat-toolbar.mat-primary {
  text-transform: uppercase !important;
}

.mat-tooltip {
  text-transform: none;
}

.mat-tooltip.capitalize-first::first-letter {
  text-transform: capitalize;
}

.mat-button,
.mat-fab,
.mat-icon-button,
.mat-raised-button {
  border-radius: 0 !important;
}

.mat-list,
.mat-nav-list,
.mat-list[dense] {
  padding-top: 0;
}

.mat-option.mat-selected.mat-primary,
.mat-primary .mat-option.mat-selected,
.selected {
  color: $selected-color !important;
}

.mat-drawer-backdrop {
  background-color: rgba(black, 0.2) !important;
  box-shadow: inset 0 0 50px 50px rgba(0, 0, 0, 0.1);
}

input,
textarea {
  text-transform: none !important;
}

.mat-list-text {
  line-height: 15px;
}

.mat-form-field-wrapper textarea {
  margin-bottom: -5px;
  width: calc(100% - 10px);
  resize: none;
}

.textarea {
  .mat-form-field-label-wrapper {
    margin-left: 5px;
  }

  .mat-form-field-underline {
    padding-right: 2px;
  }
}

textarea.mat-input-element {
  padding: 5px !important;
  margin-bottom: -5px !important;
}

.mat-select-placeholder.mat-floating-placeholder {
  visibility: hidden;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  white-space: nowrap;
  cursor: pointer;

  input {
    display: none;
  }
}

.switch-text {
  line-height: 24px;
  margin-left: 66px;
  font-size: 14px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $selected-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $selected-color;
}

input:checked + .slider:before {
  transform: translateX(35px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.mat-card > .mat-card-actions:last-child {
  margin-top: -1em;
}

.mat-input-error {
  font-size: 12px;
}

.mat-mdc-dialog-actions {
  display: flex;
  gap: 0.5rem;

  .mat-mdc-button-base {
    margin-left: 0 !important;
  }
}

.mat-select-panel {
  max-height: 300px !important;
}

.mat-list-item {
  font-size: 13px !important;
}

.mat-form-field {
  margin-top: -10px;
}

.mat-mdc-chip {
  font-size: 14px;
  border-radius: 0 !important;
}

button.isa-icon-button {
  i {
    margin: -3px 0 0 -10px;
  }
}

.mat-list-item-disabled {
  background-color: unset;
  color: #686868 !important;
}

.mat-pseudo-checkbox-disabled {
  border-color: #686868 !important;
}

.mat-table {
  background: unset;

  .mat-cell {
    font-size: 100%;
  }

  td.mat-cell,
  th.mat-header-cell,
  td.mat-footer-cell {
    border: 0 !important;
  }

  tr.mat-header-row {
    height: 100%;
  }

  th {
    height: 100%;
    vertical-align: middle;
  }
}

.mat-chip-trailing-icon {
  padding-top: 6px;
}

.mat-dialog-container:has(#ctf-task-dialog) {
  padding: 0;
}

// todo to ngx-commons - input variable
.full-body {
  .cybexer-widget-body {
    margin-top: 25px !important;
  }
}

// as of material v15 select width === parent field width
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.meta-date {
  font-size: 11px;
  font-style: italic;
  opacity: 0.6;
}
