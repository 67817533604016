@use 'sass:map';
@use '@angular/material' as mat;

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.isa-table {
  width: 100%;
  font-family: inherit !important;
  word-break: break-word;

  button {
    word-break: keep-all;
  }

  th {
    padding: 12px 16px;
    text-align: left;
    text-transform: uppercase;
    font-size: 0.7em;
    border: none !important;
  }

  td {
    padding: 16px 16px;
    border: none !important;
  }

  td.mat-mdc-cell:first-of-type,
  th.mat-mdc-header-cell:first-of-type {
    padding-left: 25px;
  }

  td.mat-mdc-cell:last-of-type,
  th.mat-mdc-header-cell:last-of-type {
    padding-right: 25px;
  }

  tr {
    transition: background-color 0.2s;
    height: 100% !important;
  }

  tr:hover {
    transition: background-color 0.2s;
  }
}

.sticky-table {
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 7;
    transition: background-color 0.2s;
    vertical-align: middle;
  }

  // This solid background will ensure that other columns aren't visible behind the sticky column when the row is hovered.
  td:first-child::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    transition: background-color 0.2s;
  }

  th:first-child::after,
  td:first-child::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    box-shadow: 4px 0 4px rgba(0, 0, 0, 0.14);
    z-index: -1;
  }
}

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $bg: map.get($color-config, background);
  $fg: map.get($color-config, foreground);

  .isa-table {
    background: map_get($bg, background);

    th {
      background: map_get($bg, app-bar);
    }

    .mat-mdc-cell .mat-mdc-header-cell {
      border-bottom: none;
    }

    .mat-mdc-header-cell {
      word-break: normal;
    }

    tr:nth-child(odd) {
      background-color: transparent;
    }

    tr:nth-child(even) {
      background-color: map_get($fg, table-row);
    }

    tr:not(.detail-row):not(.expanded-row):hover {
      background-color: map_get($bg, hover) !important;
    }
  }

  .sticky-table {
    tr:nth-child(odd) {
      td:first-child {
        background-color: map_get($bg, background);
      }
    }

    tr:nth-child(even) {
      td:first-child {
        background-color: map_get($fg, table-row);
      }
    }

    tr:hover td:first-child::before {
      background-color: map_get($bg, hover);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
