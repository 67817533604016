@use '@angular/material' as mat;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$isa-app-primary: mat.define-palette(mat.$blue-grey-palette, 600, 200, 800);
$isa-app-accent: mat.define-palette(mat.$grey-palette);

// The warn palette is optional (defaults to red).
$isa-app-warn: mat.define-palette(mat.$red-palette);

$border-color: #4b5662;
$background-color: #2b3139;
$primary-button-color: #d55858;
$main-text-color: #e3e3e3;
$dropdown-menu-background-color: #252525;
$toolbar-background-color: #303841;
$selected-color: #d55858;
$good-color: #32c559;
$compromised-color: #da932c;
$info-color: rgb(3, 169, 244); // Taken from angular2-notifications
$pending-compromised-color: #2e91a8;
$not-available-color: #d6474f;
$code-block-color: #2e3129;
$code-text-color: #63f863;

// size
$isa-top-bar-height: 64px;
$isa-top-bar-height-small-screen: 56px;
$isa-footer-height: 64px;

// Z indexes
$isa-zIndex-topbar: 7;

// Font
$isa-font-family: 'Exo 2', Roboto, 'Helvetica Neue', sans-serif;

// Light theme
$light-text-color: rgba(0, 0, 0, 0.87);

$light-color-1: white;
$light-color-2: whitesmoke;
$light-color-3: #ecdde0;
$light-color-4: #eeecec;
$light-border: #cbcbcb;

// Monaco editor
$monaco-error-overlay-border-color: rgb(241, 76, 76);
