@import 'src/assets/css/_material-app-theme';
@import './config';

html,
body,
div,
span,
button {
  font-family: $isa-font-family;
  letter-spacing: 1px;
  text-transform: uppercase;
}

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: $isa-font-family;

  .no-scroll {
    overflow: hidden;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

b,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  color: $primary-button-color;
}

.chart-container {
  padding: 25px 25px 0 25px;
}

.chart-widget-wrapper {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .chart-wrapper {
    height: 100%;
    margin-top: 15px;
    padding: 0 25px 25px;
    overflow: auto;
  }

  .horizontal-view-toggle {
    min-width: 190px;
  }
}

.widget-header {
  padding: 25px 25px 0 25px;
}

.widget-title {
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.isa-content-body:fullscreen {
  .chart {
    height: 83vh;
  }

  .incident-report .cards-container {
    height: 92vh;
  }
  .campaign-live {
    min-height: calc(100vh - 247px) !important;
  }

  .project-container,
  .timeline-wrapper,
  .summary-timeline-chart,
  .log-container mat-card {
    height: calc(100vh - 110px) !important;
  }

  .team-timeline-chart {
    height: calc(100vh - 150px) !important;
  }
}

.clickable-row {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.team-filter-dropdown {
  min-width: 150px;
}

.disable-uppercase {
  text-transform: none !important;
}

.full-width {
  width: 100%;
}

.severity {
  &.critical {
    color: #ff331b;
  }

  &.high {
    color: #ef7126;
  }

  &.medium {
    color: #deb116;
  }

  &.low {
    color: #68cb5e;
  }

  &.info {
    color: #6d7174;
  }

  &.critical-bg {
    background-color: #ff331b !important;
  }

  &.high-bg {
    background-color: #ef7126 !important;
  }

  &.medium-bg {
    background-color: #deb116 !important;
  }

  &.low-bg {
    background-color: #68cb5e !important;
  }

  &.info-bg {
    background-color: #6d7174 !important;
  }
}

.info-txt {
  opacity: 0.6;
}

isa-exercise-duration-slider {
  width: 100%;
}

.ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
.ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: 'Small' !important;
}
.ql-picker.ql-size .ql-picker-label[data-value='']::before,
.ql-picker.ql-size .ql-picker-item[data-value='']::before {
  content: 'Normal';
}
.ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: 'Large' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: 'Huge' !important;
}

.gold {
  color: #ffd100;
}

.silver {
  color: #a7a7ad;
}

.bronze {
  color: #a77044;
}

.site,
.project,
.news-inject {
  mat-card {
    padding-bottom: 25px;
  }

  .mat-card-actions {
    margin-bottom: -15px !important;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.tone-down {
  opacity: 0.8;
}

.radio-group-label {
  display: block;
  opacity: 0.75;
  font-size: 12px;
  margin-bottom: 5px;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.select-all-checkbox {
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;

  label {
    padding-right: 0.5rem !important;
    width: 100%;
    line-height: 48px;
  }

  .mdc-form-field {
    width: 100%;
  }
}

.removal-dialog-container .mat-dialog-container {
  overflow: hidden;
}

.terminal-outer {
  background: #000000 !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-width-tabs {
  .mat-mdc-tab-header {
    margin: 0 25px;
  }

  .tab-wrapper {
    padding: 8px 25px 15px;
    display: grid;
    gap: 15px;
  }
}

.mat-mdc-tab-header {
  font-size: 14px;

  .mat-mdc-tab {
    min-width: 160px;
  }
}

.breadcrumb-header {
  div.cybexer-widget-header {
    margin-bottom: 0;
  }
}

.cdk-global-scrollblock {
  overflow-y: auto;
}

markdown {
  table {
    border-collapse: collapse;
    font-family: inherit !important;
    width: 100%;

    td {
      padding: 2px 4px;
    }
  }

  pre:has(code) {
    border-radius: 0.25rem;
    background-color: $code-block-color;
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    overflow: auto;
  }

  code {
    background-color: $code-block-color;
    color: $code-text-color;
    padding: 1px 4px;
    border-radius: 2px;
  }

  blockquote {
    padding-left: 0.5rem;
    margin: 1rem 1.5rem;
    border-radius: 2px;
  }

  ul {
    list-style-type: square;
  }

  li {
    padding-top: 0.25rem;
  }
}

.md-content-text {
  margin-top: 0.5rem;
  padding: 0.25rem 1.5rem;
}

// todo move to ngx-commons
cybexer-notification {
  white-space: pre-line;
}

.monaco-editor div.view-line > span > span,
.monaco-editor div.line-numbers {
  text-transform: none;
  font-family: 'Roboto Mono', monospace !important;
}

@keyframes red-blinking {
  0% {
    color: #2b3139;
  }
  30% {
    color: rgb(214, 47, 54);
  }
  70% {
    color: rgb(214, 47, 54);
  }
  100% {
    color: #2b3139;
  }
}

.thin {
  font-weight: 100;
}

@import 'src/assets/css/_form';
@import 'src/assets/css/_expansion-panel';
@import './_table';
@import './_detail';
@import './_label';

@import './_quill-editor';
@import './_monaco-editor';
